import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PositiveAuthGuard } from 'lexi-auth';
import { PostComponent } from 'lexi-dashforge-top';
import { SurveyEntryComponent, SurveyFormComponent, WarrantyComponent } from 'survey';
import { AppointmentCalendarComponent } from './appointment-calendar/appointment-calendar.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { ChatComponent } from './chat/chat.component';
import { CustomerAppointmentComponent } from './customer-appointment/customer-appointment.component';
import { CustomerFormComponent } from './customer-form/customer-form.component';
import { CustomerJobComponent } from './customer-job/customer-job.component';
import { CustomerNewComponent } from './customer-new/customer-new.component';
import { InventoryComponent } from './inventory/inventory.component';
import { OldWarrantyComponent } from './old-warranty/old-warranty.component';
import { DashboardComponent } from './page/dashboard/dashboard.component';
import { PlatformComponent } from './platform.component';
import { ReportComponent } from './report/report.component';
import { SurveyDataComponent } from './survey-data/survey-data.component';
import { InventoryStatementComponent } from './inventory-statement/inventory-statement.component';
import { BuildingJobComponent } from './building-job/building-job.component';

const routes: Routes = [
  {path: "survey", component: SurveyEntryComponent},
  {path: "survey/:uuid", component: SurveyFormComponent},
  {path: "warranty/:uuid", component: WarrantyComponent},
  {path: "", component: PostComponent, canActivate:[PositiveAuthGuard], children: [
    {path: "", component: PlatformComponent, children: [
      {path: "", redirectTo: "dashboard", pathMatch:"full"},
      {path: "dashboard", component: DashboardComponent},
      {path: "form", loadChildren: () => import("lexi-form").then(m => m.LexiFormModule)},
      {path: "list", loadChildren: () => import("lexi-table").then(m => m.LexiTableModule)},
      {path: "builder", loadChildren: () => import("lexi-pagebuilder").then(m => m.LexiPagebuilderModule)},
      {path: "detail", loadChildren: () => import("lexi-table-menu").then(m => m.LexiTableMenuModule)},
      {path: "appointment", component: AppointmentCalendarComponent},
      {path: "warranty", component: OldWarrantyComponent},
      {path: "new", component: CustomerFormComponent},
      {path: "chat", component: ChatComponent},
      {path: "inventory", component: InventoryComponent},
      {path: "change-password", component: ChangePasswordComponent},
      {path: "report/:identifier", component: ReportComponent},
      {path: "survey-data", component: SurveyDataComponent},
      { path: "inventory-statement", component: InventoryStatementComponent },
      {path: "customer", children: [
        {path: "create/job-building", component: BuildingJobComponent}, 
        {path: "create/job", component: CustomerJobComponent}, 
        {path: "create/appointment", component: CustomerAppointmentComponent},
        {path: "appointment/:appointment_uuid", component: CustomerAppointmentComponent},
        {path: "job/:job_uuid", component: CustomerJobComponent},
        {path: "job-building/:job_uuid", component: BuildingJobComponent}, 
        {path: "new", component: CustomerNewComponent}
      ]},
      
    ]}
  ]}
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PlatformRoutingModule { }
