<div class="p-3">
    <div class="d-flex flex-row justify-content-between">
        <h3 class="px-2">{{data.type | uppercase}}</h3>
        <button class="btn border-0" (click)="closeModel()">CLOSE</button>
    </div>
    <table class="table mb-0">
        <tr *ngIf="data.appointment_title">
            <th colspan="2">{{data.appointment_title}}</th>
        </tr>
        <ng-container *ngIf="data.type == 'appointment'">
            <tr>
                <th>Name</th>
                <td>{{data.customer_name}}</td>
            </tr>
            <tr>
                <th>Email</th>
                <td>{{data.customer_email}}</td>
            </tr>
            <tr>
                <th>Contact No</th>
                <td>{{data.customer_mobile}}</td>
            </tr>
            <tr *ngIf="data.atype == 'automobile'">
                <th>Car Model</th>
                <td>{{data.car_model}}</td>
            </tr>
            <tr *ngIf="data.atype == 'automobile'">
                <th>Car Maker</th>
                <td>{{data.car_maker}}</td>
            </tr>
            <tr *ngIf="data.atype == 'automobile'">
                <th>Plate No</th>
                <td>{{data.plate_no}}</td>
            </tr>
            <tr *ngIf="data.atype == 'building'">
                <th>Address</th>
                <td>{{data.address}}</td>
            </tr>
            <tr *ngIf="data.atype == 'building'">
                <th>State</th>
                <td>{{data.state}}</td>
            </tr>
            <tr *ngIf="data.atype == 'building'">
                <th>Postcode</th>
                <td>{{data.postcode}}</td>
            </tr>
            <tr>
                <th>Date of appointment</th>
                <td>{{data.appointment_date}}</td>
            </tr>
            <tr>
                <th>Time From</th>
                <td>{{data.time}}</td>
            </tr>
            <tr>
                <th>Time To</th>
                <td>{{data.time_to}}</td>
            </tr>
        </ng-container>
        <tr>
            <th>Branch</th>
            <td>{{data.branch}}</td>
        </tr>
        <tr>
            <th>Product/Package</th>
            <td>
                <div class="w-100 p-0" *ngFor="let spec of data.spec">{{spec}}</div>
            </td>
        </tr>
        <tr>
            <th>Created By</th>
            <td>{{data.create_by}}</td>
        </tr>
        <tr>
            <th>Status</th>
            <td>{{data.status == 1 ? 'Active': (data.status == 2 ? 'Completed' : 'Cancel')}}</td>
        </tr>
        <tr *ngIf="data.remark">
            <th colspan="2">{{data.remark}}</th>
        </tr>
    </table>
    <button class="btn btn-success w-100 mb-3" (click)="convertToJob()" *ngIf="data.status == 1 && data.type == 'appointment' && !data.converted">Convert To Job</button>
    <button class="btn btn-success w-100 mb-3" (click)="viewAppointment()" *ngIf="data.type == 'appointment'">Edit Appointment</button>
    <button class="btn btn-success w-100 mb-3" (click)="viewJob()" *ngIf="data.type == 'appointment' && data.converted">View Job</button>
    <button class="btn btn-warning w-100 mb-3" (click)="sendSMSAppointment()" *ngIf="data.status == 1 && !data.converted" [disabled]="isLoading">Send SMS Notification</button>
    <button class="btn btn-danger w-100 mb-3" (click)="cancelAppointment()" *ngIf="data.status == 1 && !data.converted">Cancel {{data.type | uppercase}}</button>
    <button class="btn btn-danger w-100" (click)="deleteAppoinment()" *ngIf="data.status == 0">Delete {{data.type | uppercase}}</button>
</div>
