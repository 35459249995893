import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { LexiNotifyService } from 'lexi-notify';
@Component({
  selector: 'lib-appointment-detail',
  templateUrl: './appointment-detail.component.html',
  styleUrls: ['./appointment-detail.component.css']
})
export class AppointmentDetailComponent{

  public data:any;
  public isLoading:boolean = false;

  @Input() set setData(v:any) {
    this.data = v;
  };

  @Output() close = new EventEmitter();

  constructor(private api:ApiService, private router: Router, private notify: LexiNotifyService) { }

  async convertToJob() {
    let search:any = await this.api.post("/profile/appointment/search", {search: this.data.uuid});

    search = search.appointment;
    if(!search.customer) {
      alert("Failed to retrive customer")
      this.close.emit(true) 
      return;
    }

    let data = this.data;
        data.appointment_date = search.appointment_date;
        data.product = search.product
    delete data.onclick;

    data.car_maker = data.car_maker_id
    data.car_model = data.car_model_id

    if(data.atype == "automobile") {
      this.router.navigate(["/customer/create/job"], { state: { customer: search.customer, appointment_uuid: search.uuid, appointment: data } } )
    } else {
      this.router.navigate(["/customer/create/job-building"], { state: { customer: search.customer, appointment_uuid: search.uuid, appointment: data } } )
    }
    this.close.emit(true) 

  }

  async cancelAppointment() {
    await this.api.post("/profile/appointment/update", {status: 'cancel', appointment_uuid: this.data.uuid}) 
    this.close.emit(true) 
  }
  
  async deleteAppoinment() {
    await this.api.post("/profile/appointment/update", {status: 'delete', appointment_uuid: this.data.uuid})  
    this.close.emit(true) 
  }

  viewAppointment() {
    this.router.navigate(['/customer/appointment', this.data.uuid])    
    this.close.emit(true) 
  }

  viewJob() {
    if(this.data.atype == "automobile") {
      this.router.navigate(['/customer/job', this.data.job_uuid])    
    } else {
      this.router.navigate(['/customer/job-building', this.data.job_uuid])    
    }
    this.close.emit(true) 
  }

  closeModel() {
    this.close.emit(true) 
  }

  async sendSMSAppointment() {
    this.isLoading = true;
    let send:any = await this.api.post("/profile/appointment/sendSMSNotification", {search: this.data.uuid});
    if(send.status) {
      this.notify.success("SMS Notification Send Successfully.")
    } else {
      this.notify.error(send.message)
    }
    this.isLoading = false;
  }
}
