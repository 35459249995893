<div class="row">
    <div class="col-12 col-md-3">
        <div class="card mb-4">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Customer Information</strong></div>
            </div>
            <div class="card-body">
                <p class="mb-0">{{data.customer.name}}</p>
                <p class="mb-0">{{data.customer.email}}</p>
                <p class="mb-0">{{data.customer.mobile_no}}</p>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Service Information</strong></div>
            </div>
            <div class="card-body">
                <div class="form-group mb-3">
                    <label for="name">Installation Branch <em>*</em></label>
                    <ng-select [(ngModel)]="data.installation_branch" required placeholder="Select Branch">
                        <ng-option *ngFor="let branch of init.branch" [value]="branch.uuid">{{branch.title}}</ng-option>
                    </ng-select>
                </div>
                <div class="form-group mb-3">
                    <label for="appointment_date">Appointment Date <em>*</em></label>
                    <input type="date" id="appointment_date" class="form-control" name="appointment_date" [(ngModel)]="data.appointment_date">
                </div>
                <div class="form-group mb-3">
                    <label for="type">Appointment Type <em>*</em></label>
                    <select class="form-control" [(ngModel)]="data.type">
                        <option value="automobile">Automobile</option>
                        <option value="building">Building</option>
                    </select>
                </div>
                <div class="form-group mb-3">
                    <label for="time_from">Time From <em>*</em></label>
                    <select class="form-control" [(ngModel)]="data.time_from">
                        <option>08:00</option>
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                    </select>
                </div>
                <div class="form-group mb-3">
                    <label for="time_to">Time To <em>*</em></label>
                    <select class="form-control" [(ngModel)]="data.time_to">
                        <option>08:30</option>
                        <option>09:00</option>
                        <option>09:30</option>
                        <option>10:00</option>
                        <option>10:30</option>
                        <option>11:00</option>
                        <option>11:30</option>
                        <option>12:00</option>
                        <option>12:30</option>
                        <option>13:00</option>
                        <option>13:30</option>
                        <option>14:00</option>
                        <option>14:30</option>
                        <option>15:00</option>
                        <option>15:30</option>
                        <option>16:00</option>
                        <option>16:30</option>
                        <option>17:00</option>
                        <option>17:30</option>
                        <option>18:00</option>
                        <option>18:30</option>
                        <option>19:00</option>
                        <option>19:30</option>
                        <option>20:00</option>
                        <option>20:30</option>
                        <option>21:00</option>
                        <option>21:30</option>
                        <option>22:00</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-9">
        <nav class="card d-flex flex-row justify-content-between align-items-center bg-white mb-3 py-2 px-3">
            <input class="form-control flex-grow-1" placeholder="Enter Appointment Title" [(ngModel)]="data.appointment_title" />
            <div class="d-flex flex-row ms-2">
                <button class="btn btn-sm btn-primary me-2 text-nowrap" *ngIf="appointment_uuid" (click)="convert()">CONVERT TO JOB</button>
                <button class="btn btn-sm btn-primary" (click)="save()">{{appointment_uuid ? "SAVE":"CREATE"}}</button>
            </div>
        </nav>

        <div class="card mb-3" *ngIf="data.type == 'automobile'">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Vehicle Information</strong></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group mb-3 col">
                        <label for="plate_no">Plate No</label>
                        <input type="text" class="form-control" name="plate_no" [(ngModel)]="data.plate_no" placeholder="Enter Plate No"/>
                    </div>
                    <div class="form-group mb-3 col">
                        <label for="car_maker">Car Maker</label>
                        <ng-select [(ngModel)]="data.car_maker" (change)="filterModel()" placeholder="Select Car Maker">
                            <ng-option *ngFor="let maker of init.maker" [value]="maker.id">{{maker.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col">
                        <label for="car_model">Car Model</label>
                        <ng-select [(ngModel)]="data.car_model" [disabled]="!data.car_maker" placeholder="Select Car Model">
                            <ng-option *ngFor="let model of init.filter_model" [value]="model.id">{{model.title}}</ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
        </div>

        <div class="card mb-3" *ngIf="data.type == 'building'">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Vehicle Information</strong></div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="form-group mb-3 col-12">
                        <label for="address">Address</label>
                        <input type="text" class="form-control" name="address" id="address" [(ngModel)]="data.address" />
                    </div>
                    <div class="form-group mb-3 col-3">
                        <label for="state">State</label>
                        <ng-select [(ngModel)]="data.state" placeholder="Select State" >
                            <ng-option value="kuala-lumpur">Kuala Lumpur</ng-option>
                            <ng-option value="selangor">Selangor</ng-option>
                            <ng-option value="labuah">Labuan</ng-option>
                            <ng-option value="putrajaya">Putrajaya</ng-option>
                            <ng-option value="johor">Johor</ng-option>
                            <ng-option value="kedah">Kedah</ng-option>
                            <ng-option value="malacca">Malacca</ng-option>
                            <ng-option value="negeri-sembilan">Negeri Sembilan</ng-option>
                            <ng-option value="pahang">Pahang</ng-option>
                            <ng-option value="penang">Penang</ng-option>
                            <ng-option value="perak">Perak</ng-option>
                            <ng-option value="perlis">Perlis</ng-option>
                            <ng-option value="sabah">Sabah</ng-option>
                            <ng-option value="sarawak">Sarawak</ng-option>
                            <ng-option value="kelantan">Kelantan</ng-option>
                            <ng-option value="terengganu">Terengganu</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col-3">
                        <label for="postcode">Postcode</label>
                        <input type="text" class="form-control" name="postcode" id="postcode" [(ngModel)]="data.postcode" />
                    </div>
                </div>
            </div>
        </div>
        <div class="card mb-3">
            <div class="card-header bg-gray">
                <div class="card-title mb-0 justify-content-between d-flex flex-row">
                    <strong>Product Information</strong> 
                    <button class="btn border-0 p-0" (click)="addNewProduct()">
                        <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m21 3.998c0-.478-.379-1-1-1h-16c-.62 0-1 .519-1 1v16c0 .621.52 1 1 1h16c.478 0 1-.379 1-1zm-16.5.5h15v15h-15zm6.75 6.752h-3.5c-.414 0-.75.336-.75.75s.336.75.75.75h3.5v3.5c0 .414.336.75.75.75s.75-.336.75-.75v-3.5h3.5c.414 0 .75-.336.75-.75s-.336-.75-.75-.75h-3.5v-3.5c0-.414-.336-.75-.75-.75s-.75.336-.75.75z" fill-rule="nonzero"/></svg>
                        ADD
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row" *ngFor="let product of data.product; let i = index">
                    <div class="form-group mb-3 col-2">
                        <label for="time_to">Category</label>
                        <ng-select [(ngModel)]="product.category" (change)="filterCategory(product)" placeholder="Select Category">
                            <ng-option *ngFor="let category of init.categories" [value]="category.id">{{category.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col-6">
                        <label for="time_to">Product</label>
                        <ng-select [(ngModel)]="product.product" [disabled]="!product.category" placeholder="Select Product">
                            <ng-option *ngFor="let p of product.filter_product" [value]="p.id">{{p.title}}</ng-option>
                        </ng-select>
                    </div>
                    <div class="form-group mb-3 col-2">
                        <label *ngIf="product.product">Warranty</label><br/>
                        <span *ngIf="product.product" [innerHTML]="showWarranty(product)">
                        </span>
                    </div>
                    <div class="form-group mb-3 col-2 text-end">
                        <button class="btn border-0 p-0" (click)="removeProduct(i)" *ngIf="data.product.length > 1">
                            <svg clip-rule="evenodd" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="m4.015 5.494h-.253c-.413 0-.747-.335-.747-.747s.334-.747.747-.747h5.253v-1c0-.535.474-1 1-1h4c.526 0 1 .465 1 1v1h5.254c.412 0 .746.335.746.747s-.334.747-.746.747h-.254v15.435c0 .591-.448 1.071-1 1.071-2.873 0-11.127 0-14 0-.552 0-1-.48-1-1.071zm14.5 0h-13v15.006h13zm-4.25 2.506c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm-4.5 0c-.414 0-.75.336-.75.75v8.5c0 .414.336.75.75.75s.75-.336.75-.75v-8.5c0-.414-.336-.75-.75-.75zm3.75-4v-.5h-3v.5z" fill-rule="nonzero"/></svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="card">
            <div class="card-header bg-gray">
                <div class="card-title mb-0"><strong>Other Information</strong></div>
            </div>
            <div class="card-body">
                <div class="form-group mb-3">
                    <label for="time_to">Remark</label>
                    <textarea class="form-control" rows="5" [(ngModel)]="data.remark">
                    </textarea>
                </div>
            </div>
        </div>
    </div>
</div>
