import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from 'lexi-api';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CustomerNewComponent } from '../customer-new/customer-new.component';

@Component({
  selector: 'lib-customer-form',
  templateUrl: './customer-form.component.html',
  styleUrls: ['./customer-form.component.css']
})
export class CustomerFormComponent implements OnInit {

  public results:any = [];

  constructor(private api: ApiService, private router: Router, private ngbmodal: NgbModal) { }

  ngOnInit(): void {
  }

  async search(val:any) {
    let value = val.target.value;

    if(value.length < 3) {
      return;
    }

    let search = await this.api.post("/profile/customer/search", {search: value});

    this.results = search;
  }

  appointment(customer:any) {
    this.router.navigate(["/customer/create/appointment"], { state: { customer } } )
  }
  
  job(customer:any) {
    this.router.navigate(["/customer/create/job"], { state: { customer } } )
  }

  buildingJob(customer:any) {
    this.router.navigate(["/customer/create/job-building"], { state: { customer } } )
  }

  create() {
    let modal = this.ngbmodal.open(CustomerNewComponent, {backdrop: "static"})

    modal.componentInstance.setClose.subscribe(() => {
      modal.close();
    });
  }

  view(customer:any) {
    console.log(customer)
    this.router.navigate(["/customer/view", customer.id] )
  }
}
