<div class="row">
    <div class="col-12 col-md-4">
        <h4>Operation</h4>
        <hr/>
        <label for="customer">Select / Add Customer</label>
        <div class="input-group mb-3">
            <input type="text" id="customer" class="form-control" placeholder="Search Customer By name, phone or email" (input)="search($event)">
            <a class="btn btn-primary" (click)="create()">Create</a>
        </div>
    </div>
</div>

<div class="row" *ngIf="results.length > 0">
    <div class="col-12 col-md-6">
        <table class="w-100">
            <tr>
                <th>No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Mobile</th>
                <th colspan="2">Create</th>
            </tr>
            <tr *ngFor="let result of results; let i = index">
                <td>{{i + 1}}</td>
                <td class="text-nowrap px-2">{{result.name}}</td>
                <td class="px-2 text-nowrap">{{result.email}}</td>
                <td class="px-2 text-nowrap">{{result.mobile_no}}</td>
                <td class="px-2">
                    <div class="btn-group btn-group-sm"> 
                        <button type="button" class="btn btn-outline-primary btn-sm py-1 px-2 text-nowrap" (click)="appointment(result)" *ngIf="result.canSetAppointment">Set Appointment</button>
                        <button type="button" class="btn btn-outline-primary btn-sm py-1 px-2 text-nowrap" (click)="job(result)">Create Automobile Job</button>
                        <button type="button" class="btn btn-outline-primary btn-sm py-1 px-2 text-nowrap" (click)="buildingJob(result)">Create Building Job</button>
                    </div>
                </td>      
            </tr>
        </table>
    </div>
</div>
